<template>
  <div class="app-page">
    <div class="side-menu">
      <app-side-menu />
    </div>

    <div class="page-content">
      <div class="mb-4 app-title">
        <a href="#" class="btn btn-primary btn-xs float-right"><i class="lni-money-pritection mr-2"></i> Balance: KES {{ $number.format(app.balance) }}</a>
        <h2>{{ app.name }} <span :class="`badge badge-${app.environment == 'live' ? 'success' : 'info'}`">{{ app.environment }}</span></h2>
      </div>

      <router-view />
      
    </div>
  </div>
</template>

<script>
import AppSideMenu from '@/components/dashboard/AppSideMenu'

export default {
  components: {
    AppSideMenu
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {}
  },

  methods: {
    fetch() {
      this.$loader.start()

      this.$axios.get(`/api/v1/apps/${this.$route.params.id}`).then(response => {
        this.$store.dispatch('setApp', response.data.current_app)
        this.$store.dispatch('setApps', response.data.all_apps)
        this.$loader.stop()
      })
    }
  },

  computed: {
    app() {
      return this.$store.getters.getApp
    },

    current_app() {
      return this.$route.params.id
    }
  },

  watch: {
    current_app() {
      this.fetch()
    }
  }
}
</script>