<template>
  <ul>
    <li>
      <router-link :to="{ name: 'app.overview' }"><i class="lni-dashboard icon"></i> Overview</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.top-up' }"><i class="lni-money-protection icon"></i> Top Up</router-link>
    </li>
    <li class="title">Bulk SMS</li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.sender-id' }"><i class="lni-user icon"></i> Sender ID</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.templates' }"><i class="lni-files icon"></i> Message Templates</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.contact-lists.index' }"><i class="lni-users icon"></i> Contact Lists</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.send' }"><i class="lni-bubble icon"></i> Send SMS</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.outbox' }"><i class="lni-bookmark icon"></i> Outbox</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.sent' }"><i class="lni-reply icon"></i> Sent Messages</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.analytics' }"><i class="lni-bar-chart icon"></i> Analytics</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.getting-started' }"><i class="lni-layers icon"></i> Developers</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.callbacks' }"><i class="lni-link icon"></i> Callback Urls</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.bulk-sms.errors' }"><i class="lni-warning icon"></i> Error Logs</router-link>
    </li>
    <li class="title">Settings</li>
    <li>
      <router-link :to="{ name: 'app.account-statement' }"><i class="lni-shield icon"></i> Account Statement</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.key' }"><i class="lni-pencil icon"></i> App Key</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.environment' }"><i class="lni-pyramids icon"></i> Environment</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.members' }"><i class="lni-users icon"></i> Members</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'app.settings' }"><i class="lni-cog icon"></i> Settings</router-link>
    </li>
    <li v-if="user.role == 'admin'">
      <router-link :to="{ name: 'app.pricing' }"><i class="lni-pencil icon"></i> Pricing</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>